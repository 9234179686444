.sib__container {
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    border-color: rgba(128, 128, 128, 0.75); 
}



.sib__number {
    font-size: 50px
}

.sib__label {
    font-size: 25px;
}

.sib__input {
    width: 75%;
    height: 50%;

    max-width: 200px;

    text-align: center;

    font-size: 50px;
    
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    border-color: rgba(128, 128, 128, 0.75); 
       
}

.sib__input:focus {
  outline: none;
  border-color: rgba(140, 72, 67, 1);
}

//Slider
input[type=range] {
    height: 33px;
    -webkit-appearance: none;
    margin: auto;
    width: 90%;
    background-color: rgba(0,0,0,0);
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 15px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 2px 2px 2px #000000;
    background: #8C4843;
    border-radius: 50px;
    border: 2px solid #000000;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 2px 2px 7px #000000;
    border: 1px solid #000000;
    height: 24px;
    width: 22px;
    border-radius: 10px;
    background: #8ACDEA;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #8C4843;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 15px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 2px 2px 2px #000000;
    background: #8C4843;
    border-radius: 50px;
    border: 2px solid #000000;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 2px 2px 7px #000000;
    border: 1px solid #000000;
    height: 24px;
    width: 22px;
    border-radius: 10px;
    background: #8ACDEA;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 15px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #8C4843;
    border: 2px solid #000000;
    border-radius: 100px;
    box-shadow: 2px 2px 2px #000000;
  }
  input[type=range]::-ms-fill-upper {
    background: #8C4843;
    border: 2px solid #000000;
    border-radius: 100px;
    box-shadow: 2px 2px 2px #000000;
  }
  input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 2px 2px 7px #000000;
    border: 1px solid #000000;
    height: 24px;
    width: 22px;
    border-radius: 10px;
    background: #8ACDEA;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #8C4843;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #8C4843;
  }