.home__container {
    height: 95vh;
    min-width: 300px;
}

.warning__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vh;
  background-color: red;
  
  
}

.warning {
  text-align: center;
  width: 100%;
  max-width: 600px;
  
}

.warning__text {
  font-size: 10%;
}

.home__title {
    border-style: solid;
    padding: 10px;
    padding-left: 20px;
    background-color: rgba(140, 72, 67, 1); 
    font-size: 20px;  
}

.main__section__container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
}
.calculator__container {
    background-color: rgba(255, 255, 255, 0);
    text-align: center;
    
    max-width: 500px;
    width: 100%;
    margin: 20px;
}

.units_container {
    display: flex;
    justify-content: center;
}

.weight__type__container {
    font-size: 30px;
    margin-right: 5px;
    margin-left: 5px;
}

input {
    width: 20px;
    height: 20px;
}




// Weight //

.weight__input {
    border-style: solid;
    font-size: 50px;
    text-align: center;
    max-width: 200px;
    width: 75%;
    height: 50%;
    border-radius: 10px;
    border-color: #8C4843;    
}

//Rep Content

.select__reps__container {
    margin-top: 10px;
    padding-top: 10px;
    border-style: solid;
  
}

.reps__number {
    font-size: 50px
}

.reps__label {
    font-size: 25px;
}

//Slider
input[type=range] {
    height: 33px;
    -webkit-appearance: none;
    margin: auto;
    width: 90%;
    background-color: rgba(0,0,0,0);
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 15px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 2px 2px 2px #000000;
    background: #8C4843;
    border-radius: 50px;
    border: 2px solid #000000;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 2px 2px 7px #000000;
    border: 1px solid #000000;
    height: 24px;
    width: 22px;
    border-radius: 10px;
    background: #8ACDEA;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #8C4843;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 15px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 2px 2px 2px #000000;
    background: #8C4843;
    border-radius: 50px;
    border: 2px solid #000000;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 2px 2px 7px #000000;
    border: 1px solid #000000;
    height: 24px;
    width: 22px;
    border-radius: 10px;
    background: #8ACDEA;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 15px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #8C4843;
    border: 2px solid #000000;
    border-radius: 100px;
    box-shadow: 2px 2px 2px #000000;
  }
  input[type=range]::-ms-fill-upper {
    background: #8C4843;
    border: 2px solid #000000;
    border-radius: 100px;
    box-shadow: 2px 2px 2px #000000;
  }
  input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 2px 2px 7px #000000;
    border: 1px solid #000000;
    height: 24px;
    width: 22px;
    border-radius: 10px;
    background: #8ACDEA;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #8C4843;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #8C4843;
  }

//////// One-Rep-Max ////////

.orm__container {
    border-style: solid;
    border-radius: 10px;
    border-width: 2px;
    border-color: rgba(128, 128, 128, 0.75); 
    margin-top: 10px;
    padding-bottom: 10px;
}

.orm__number {
    font-size: 75px;
}

.orm__label {
    font-size: 20px;
}

//Advanced Link
.advanced__link {
  font-size: 16px;
  margin: 2px;
}

#calculator {
  width: 600px; 
  height: 400px;

}

.extra {
  padding-bottom: 500px;
}
  